<template>
  <v-container fluid>
    <v-card
      flat
      class="align-center justify-center"
      v-if="getters_role.length > 0"
    >
      <v-toolbar primary-title dark primary> Role and Permissions </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-tooltip allow-overflow right>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-select
                    outlined
                    hide-details="auto"
                    label="Role"
                    disabled
                    :items="getters_role"
                    item-text="title"
                    item-value="id"
                    v-model="editroleName"
                    class="mb-4"
                  ></v-select>
                </span>
              </template>
              <span>Cannot be edited</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-select
          outlined
          v-model="editPermission"
          hide-details="auto"
          chips
          label="Permissions"
          :items="getters_permission"
          item-text="title"
          item-value="id"
          multiple
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon
                  :color="editPermission.length > 0 ? 'indigo darken-4' : ''"
                >
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template #append></template>
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              color="primary"
              dark
              v-bind="attrs"
              :input-value="selected"
              close
              @click="select"
              @click:close="deleteaccessBtn(item.id)"
            >
              <strong>{{ item.title }}</strong>
            </v-chip>
          </template>
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-footer color="transparent" app>
          <v-btn color="grey darken-1" text @click="dialog = false">
            cancel
          </v-btn>
          <v-btn
            :loading="disabled"
            :disabled="disabled"
            @click="saveEditRole()"
            color="success"
          >
            update
          </v-btn>
        </v-footer>
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
  import {
    computed,
    inject,
    onMounted,
    provide,
    reactive,
    toRefs,
    watch,
  } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  export default {
    setup(props, ctx) {
      const { getRole, removeRole, updateRolePermission } = useActions([
        "getRole",
        "removeRole",
        "updateRolePermission",
      ]);

      const { getters_role, getters_permission } = useGetters([
        "getters_role",
        "getters_permission",
      ]);

      const editRoleForm = reactive({
        editroleName: null,
        dialog: false,
        editPermission: [],
        adminResponse: false,
        isLoaded: true,
        isadmin: true,
        adminPassword: "",
        checkAdmin: "",
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        actionResponse: false,
        disabled: false,
        trackeditPermissionChanges: false,
        color: null,
        basketDrawer: [],
      });

      const {
        disabled,
        actionResponse,
        editroleName,
        dialog,
        editPermission,
        adminPassword,
        msgHeader,
        msgBody,
        msgIcon,
        trackeditPermissionChanges,
        color,
      } = toRefs(editRoleForm);

      getRole().then(() => {
        editRoleForm.isLoaded = false;
      });

      const currRole = inject("currRole");

      const removeRoleBtn = (id, item) => {
        actionResponse.value = false;

        removeRole(id).catch(() => {
          actionResponse.value = true;
          msgHeader.value = "Error";
          msgBody.value =
            item.title +
            " role cannot be deleted.It is used in other component(s)";
          msgIcon.value = "mdi-close-circle";
        });
      };

      const basketWithIndex = computed(() => {
        return getters_role.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));
      });

      onMounted(() => {
        let index = getters_role.value.findIndex(
          (i) => i.id == currRole.value.id
        );
        editroleName.value = currRole.value.id;
        dialog.value = true;
        editPermission.value = [];
        getters_role.value[index].permissions.forEach((access) => {
          currRole.value.permissions.forEach((item_perm) => {
            if (
              access.id === item_perm.id &&
              !editPermission.value.includes(access.id)
            ) {
              editPermission.value.push(access.id);
            }
          });
        });
      });

      const deleteaccessBtn = (id) => {
        editPermission.value.splice(editPermission.value.indexOf(id), 1);
        editPermission.value = [...editPermission.value];
      };

      const removeError = () => {
        if (adminPassword.value == "") {
          ctx.refs.editRoleForm.resetValidation();
        }
      };

      const saveEditRole = () => {
        if (!trackeditPermissionChanges.value) return;
        disabled.value = true;
        actionResponse.value = false;
        updateRolePermission({
          role: editroleName.value,
          permission: editPermission.value,
        })
          .then(() => {
            disabled.value = false;
            actionResponse.value = true;
            msgHeader.value = "ActionResponse";
            msgBody.value =
              " Role and its permission(s)" + " is successfully updated.";
            msgIcon.value = "mdi-check-outline";
          })
          .catch(() => {
            disabled.value = false;
            actionResponse.value = true;
            msgHeader.value = "Error";
            msgBody.value = "Try again later or call the System Administrator";
            msgIcon.value = "mdi-close-circle";
          });
      };

      watch(
        () => [...editPermission.value],
        (curr, old) => {
          old.length != 0 ? (trackeditPermissionChanges.value = true) : null;
        },
        { deep: true }
      );

      const selectAll = computed(() => {
        return getters_permission.value.length === editPermission.value.length;
      });

      const selectSome = computed(() => {
        return (
          editPermission.value.length > 0 &&
          editPermission.value.length < getters_permission.value.length
        );
      });

      const icon = computed(() => {
        if (selectAll.value) return "mdi-checkbox-marked";
        if (selectSome.value) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      });

      const toggle = () => {
        if (selectAll.value) {
          editPermission.value = [];
        } else {
          editPermission.value = [];
          getters_permission.value.forEach((i) =>
            editPermission.value.push(i.id)
          );
        }
      };

      provide("color", color);

      return {
        toggle,
        icon,
        ...toRefs(editRoleForm),
        basketWithIndex,
        removeRoleBtn,
        removeError,
        deleteaccessBtn,
        getters_permission,
        saveEditRole,
        getters_role,
      };
    },
  };
</script>
