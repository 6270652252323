<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form ref="roleForm" @submit.prevent>
              <v-row justify="center" align="center">
                <v-col cols="12" sm="5" md="5" lg="5">
                  <v-text-field
                    outlined
                    label="Role Name"
                    v-model="roleName"
                    :rules="[(v) => !!v || 'Role Name is required']"
                    clearable
                    @keyup.enter="saveRoleBtn"
                    :loading="loading"
                    loader-height="2"
                    hide-details="auto"
                    :error-messages="error_msgs"
                  ></v-text-field>
                </v-col>

                <v-col class="text-center" cols="12" sm="2" md="2" lg="2">
                  <v-btn
                    x-large
                    :disabled="clickOnce"
                    @click="saveRoleBtn"
                    class="mx-2"
                    fab
                    dark
                    color="primary"
                  >
                    <v-icon x-large dark> mdi-plus </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-col>
        <v-form ref="editRoleForm">
          <v-data-table
            :headers="headers"
            :items="basketWithIndex"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
            fixed-header
          >
            <template v-slot:item.edit="props">
              <v-btn
                dark
                right
                large
                icon
                @click="openrolePermissions(props.item)"
                @click.stop="dialog = true"
              >
                <v-icon dark class="success--text"> mdi-pencil-outline </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <v-edit-dialog>
                <v-btn dark right large icon>
                  <v-icon dark class="red--text"> mdi-close-circle </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-card flat>
                    <v-card-text class="pa-0 ma-0">
                      <v-text-field
                        class="mt-5"
                        v-model="adminPassword"
                        label="Enter Administrator's password"
                        outlined
                        :error-messages="checkAdmin"
                        type="password"
                        @keyup="ifAdmin"
                        @blur="removeError"
                      >
                        <template v-slot:append>
                          <v-fade-transition>
                            <v-progress-circular
                              v-if="adminResponse"
                              color="info"
                              size="24"
                              indeterminate
                            ></v-progress-circular>
                          </v-fade-transition>
                        </template>
                      </v-text-field>
                    </v-card-text>
                    <v-card-actions class="px-0 ma-0">
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="isadmin"
                        color="error"
                        @click="removeRoleBtn(item.id, item)"
                      >
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.permissions`]="{ item }">
              <template v-for="(access, i) in item.permissions">
                <v-chip color="primary" v-if="i < 2" dark :key="i" class="ma-1">
                  {{ access.title }}
                </v-chip>
              </template>
              <span v-if="item.permissions.length > 2">
                <v-icon small color="black">mdi-plus</v-icon>
                <span>{{ `( ${item.permissions.length - 2} more ) ` }}</span>
              </span>

              <span v-if="item.permissions.length == 0">
                No Permission(s) Yet
              </span>
            </template>
            <template v-slot:no-data>
              <span class="subheading font-weight-bold">No Role available</span>
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <nav>
      <v-navigation-drawer
        v-model="dialog"
        fixed
        :permanent="dialog"
        right
        width="70%"
        clipped
        class="shadow"
      >
        <div class="mt-15 d-flex justify-space-between">
          <v-btn
            class="ma-2"
            @click="
              dialog = !dialog;
              basket.pop();
            "
            v-if="basket.length > 1"
            icon
          >
            <v-icon>mdi-arrow-left-bold-circle</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            to="/access_control_management/role"
            @click="dialog = false"
            icon
            class="ma-2"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </div>
        <RolePermission v-if="dialog" />
      </v-navigation-drawer>
    </nav>

    <Response style="z-index: 999" v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import {
    computed,
    getCurrentInstance,
    provide,
    reactive,
    toRefs,
    watch,
  } from "vue";
  import debounce from "@/debounce/debounce";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";
  import RolePermission from "@/components/Access/RolePermission";
  import { uuid } from "vue-uuid";
  export default {
    components: { Response, RolePermission },
    setup() {
      const vm = getCurrentInstance();
      const {
        saveRole,
        getRole,
        signIn,
        removeRole,
        getAllPermission,
        updateRolePermission,
      } = useActions([
        "saveRole",
        "getRole",
        "signIn",
        "removeRole",
        "getAllPermission",
        "updateRolePermission",
      ]);

      const { getters_role, user, getters_rolealreadyexist, getters_permission } =
        useGetters([
          "getters_role",
          "user",
          "getters_rolealreadyexist",
          "getters_permission",
        ]);

      const roleForm = reactive({
        roleName: null,
        loading: false,
        basket: {},
        clickOnce: false,
        error_msgs: "",
      });

      const editRoleForm = reactive({
        editroleName: null,
        dialog: false,
        editPermission: [],
        adminResponse: false,
        isLoaded: true,
        isadmin: true,
        adminPassword: "",
        checkAdmin: "",
        headers: [
          { text: "#", value: "index" },
          { text: "ROLE", value: "title", width: "200px" },
          { text: "PERMISSIONS", value: "permissions" },
          {
            text: "EDIT",
            value: "edit",
            sortable: false,
            align: "center",
          },
          {
            text: "DELETE",
            value: "delete",
            sortable: false,
            align: "center",
          },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        deleteResponse: false,
        actionResponse: false,
        disabled: false,
        trackeditPermissionChanges: false,
        color: null,
        currRole: null,
        namespace: "65f9af5d-f23f-4065-ac85-da725569fdcd",
      });

      const {
        disabled,
        actionResponse,
        editroleName,
        editPermission,
        checkAdmin,
        isadmin,
        adminPassword,
        adminResponse,
        msgHeader,
        msgBody,
        msgIcon,
        deleteResponse,
        trackeditPermissionChanges,
        color,
        currRole,
        namespace,
      } = toRefs(editRoleForm);

      let { roleName, basket, clickOnce, error_msgs, loading } = toRefs(roleForm);

      watch(
        () => [adminPassword.value, roleName.value],
        () => {
          if (adminPassword.value.length == 0 || adminPassword.value == "") {
            checkAdmin.value = "";
            adminResponse.value = false;
          }
          if (roleName.value) {
            error_msgs.value = "";
          }
        }
      );

      getAllPermission();

      const ifAdmin = debounce(() => {
        if (adminPassword.value == "") {
          adminResponse.value = false;
          return;
        }
        adminResponse.value = true;
        isadmin.value = true;
        if (vm.proxy.$refs.editRoleForm.validate()) {
          let credentials = {
            email: user.value.email,
            password: adminPassword.value,
          };
          signIn(credentials)
            .then(() => {
              isadmin.value = false;
              adminResponse.value = false;
              checkAdmin.value = "";
            })
            .catch(() => {
              checkAdmin.value = "authentication failed...";
              adminResponse.value = false;
              isadmin.value = true;
            });
        } else {
          checkAdmin.value = "";
        }
      });

      const removeRoleBtn = (id, item) => {
        deleteResponse.value = false;

        removeRole(id).catch(() => {
          deleteResponse.value = true;
          msgHeader.value = "Error";
          msgBody.value =
            item.title +
            " role cannot be deleted. It is used in other component(s)";
          msgIcon.value = "mdi-close-circle";
        });
      };

      getRole().then(() => {
        editRoleForm.isLoaded = false;
      });

      const saveRoleBtn = () => {
        error_msgs.value = "";
        clickOnce.value = true;
        basket.value = {
          title: roleName.value,
        };

        if (vm.proxy.$refs.roleForm.validate()) {
          error_msgs.value = "";
          loading.value = true;
          saveRole(basket.value).then(() => {
            loading.value = false;
            vm.proxy.$refs.roleForm.reset();
            clickOnce.value = false;
            getters_rolealreadyexist.value === true
              ? (error_msgs.value = basket.value.title + " already exists...")
              : (error_msgs.value = "");
          });
        }
        clickOnce.value = false;
      };

      const basketWithIndex = computed(() => {
        return getters_role.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));
      });

      const openrolePermissions = (item) => {
        currRole.value = item;
        vm.proxy.$router.replace({
          path: `role/:/rolepermission/${uuid.v5(
            item.id.toString(),
            namespace.value
          )}`,
        });
      };

      provide("currRole", currRole);

      const deleteaccessBtn = (id) => {
        editPermission.value.splice(editPermission.value.indexOf(id), 1);
        editPermission.value = [...editPermission.value];
      };

      const removeError = () => {
        if (adminPassword.value == "") {
          vm.proxy.$refs.editRoleForm.resetValidation();
        }
      };

      const saveEditRole = () => {
        if (!trackeditPermissionChanges.value) return;
        disabled.value = true;
        actionResponse.value = false;
        deleteResponse.value = false;
        updateRolePermission({
          role: editroleName.value,
          permission: editPermission.value,
        })
          .then(() => {
            disabled.value = false;
            actionResponse.value = true;
            msgHeader.value = "ActionResponse";
            msgBody.value =
              " Role and its permission(s) is successfully updated.";
            msgIcon.value = "mdi-check-outline";
          })
          .catch(() => {
            disabled.value = false;
            deleteResponse.value = true;
            msgHeader.value = "Error";
            msgBody.value = "Try again later or call the System Administrator";
            msgIcon.value = "mdi-close-circle";
          });
      };

      watch(
        () => [...editPermission.value],
        (curr, old) => {
          old.length != 0 ? (trackeditPermissionChanges.value = true) : null;
        },
        { deep: true }
      );

      const selectAll = computed(() => {
        return getters_permission.value.length === editPermission.value.length;
      });

      const selectSome = computed(() => {
        return (
          editPermission.value.length > 0 &&
          editPermission.value.length < getters_permission.value.length
        );
      });

      const icon = computed(() => {
        if (selectAll.value) return "mdi-checkbox-marked";
        if (selectSome.value) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      });

      const toggle = () => {
        if (selectAll.value) {
          editPermission.value = [];
        } else {
          editPermission.value = [];
          getters_permission.value.forEach((i) =>
            editPermission.value.push(i.id)
          );
        }
      };

      provide("color", color);

      return {
        toggle,
        icon,
        ...toRefs(roleForm),
        ...toRefs(editRoleForm),
        saveRoleBtn,
        basketWithIndex,
        ifAdmin,
        removeRoleBtn,
        removeError,
        deleteaccessBtn,
        openrolePermissions,
        getters_permission,
        saveEditRole,
        getters_role,
        uuid,
      };
    },
  };
</script>
